import { dataLayerEventParamsTemplate, pushDataLayer } from './baseDataLayer';

const homeBaseLayer = {
  page: '/pyme/cotizacion/landing',
  page_title: 'Pyme - Cotización - Landing',
};

export const clickActionsEvents = {
  recoverHiringBody: 'Click Retoma tu contratación – Body',
  hireBody: 'Click botón Cotiza ahora – Body',
  hirePlans: 'Click botón Cotiza ahora – Bajo página',
  seePlans: 'Click link Ver detalle de planes – Bajo página',
  seeCoverages: 'Click botón Ver todas las coberturas',
  coveragesDetail: (detail: string) => `Click link Ver detalle – ${detail}`,
  seeBenefits: 'Click link Revisa todos los beneficios',
  seeCircles: 'Click link Ir a Circulo',
  quoteSection: (link: string) => `Click link ${link}`,
  hireFooter: 'Click botón Cotiza ahora - Footer',
  hireFloatingBox: 'Click botón Cotiza online – Pop-up',
  seeDetailPlan: (detail: string) => `Click link Ver más detalles del plan – ${detail}`,
  btnBannerPromo: (promo: string) => `Click promo banner landing ${promo}`,
  btnRequeriments: 'Click link Ver requisitos –body',
};

const homeEvents = {
  hiringFloatClick: {
    ...homeBaseLayer,
    action_type: 'click',
    action: 'Click botón Cotiza online – Pop-up',
  },
};

const trackViewPageEvent = (attribution = '') =>
  pushDataLayer({
    eventParams: {
      ...dataLayerEventParamsTemplate,
      ...homeBaseLayer,
      action_type: 'Pageview',
      action: 'Paso 0 -  Pyme Cotización - Landing',
      attribution,
    },
    user: { userId: '', userEmail: '' },
  });

const trackClick = ({ action }: { action: string }) =>
  pushDataLayer({
    eventParams: {
      ...dataLayerEventParamsTemplate,
      ...homeBaseLayer,
      action_type: 'click',
      action,
    },
    user: { userId: '', userEmail: '' },
  });

export { trackViewPageEvent, trackClick, homeEvents };
