import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@BICE_Vida/components';

import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import HeaderCustom from '@/components/HeaderCustom';
import PromoHeader from '@/components/PromoHeader';

import { appTitle } from '@/utils/constants';
import { headerSections, headerSectionTexts } from '@/utils/generalTexts';
import routes from '@/utils/routes';
import { useCyberDates, useBranding } from '@/src/customHooks';

import * as wizardActions from '@/redux/wizard/actions';
import * as wizardSelectors from '@/redux/wizard/selectors';

import styles from './LayoutCustom.module.scss';
import { trackClick } from '@/utils/dataLayer/home';
import { EventParamsT } from '@/utils/dataLayer/baseDataLayer';

type LayoutT = {
  children?: JSX.Element | JSX.Element[];
  title?: string;
  shouldRenderCyberDayHeader?: boolean;
  eventParams?: EventParamsT;
};

function LayoutCustom({
  children,
  title = appTitle,
  shouldRenderCyberDayHeader = false,
  eventParams,
}: LayoutT) {
  const router = useRouter();
  useBranding();
  const { promo } = useSelector(wizardSelectors.getBranding);
  const promoCyberData = useCyberDates(new Date());
  const { isCyberDayToday } = promoCyberData;

  const branding = useSelector(wizardSelectors.getBranding);

  const hiringBoxRef = useRef<HTMLDivElement>(null);
  const [identifier, setIdentifier] = useState('');

  const dispatch = useDispatch();
  const rehydrated = useSelector(({ _persist }: any) => _persist?.rehydrated);

  const onScroll = useCallback(() => {
    const windowHeight = window.innerHeight;
    const hiringBox = hiringBoxRef?.current;
    const scroll = window.pageYOffset;

    if (hiringBox && hiringBox.getBoundingClientRect().y <= windowHeight - 50) {
      const sectionTop: number = -scroll || 0;
      const offset: number = -scroll / 2;
      const opacity = 1 - (sectionTop - offset + 300) / 100;

      hiringBox.style.setProperty('--opacity', `${opacity - 1}`);
      hiringBox.style.setProperty('--position-y', `-${sectionTop}px`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (rehydrated) {
      dispatch(wizardActions.resetWizard());
    }
  }, [rehydrated]);

  const btnRecoverReHiringClick = () => {
    trackClick({ action: `Click Retoma tu contratación - Menú` });

    router.push(routes.recoverHiring);
  };

  useEffect(() => {
    const id =
      Object.keys(routes).find((key: string) => (routes[key] === router.pathname ? key : '')) || '';
    setIdentifier(id);
  }, []);

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
      </Head>
      <HeaderCustom
        brandingLogo={branding?.logo}
        sticky
        logoRedirect
        children={
          <Fragment>
            <div>
              <Navbar sections={headerSections} active={identifier} />
              <div className={styles.header_divider} />
              <div className={styles.btn_container}>
                <Button
                  id={`${identifier}_header_btn_recover_hiring`}
                  text={headerSectionTexts.btnRecoverHiring}
                  size="medium"
                  variant="text"
                  onClick={btnRecoverReHiringClick}
                />
              </div>
            </div>
          </Fragment>
        }
      />
      {isCyberDayToday && (
        <div className={styles.sticky}>
          <PromoHeader {...promoCyberData} />
        </div>
      )}
      {isCyberDayToday ? <div className={styles.layout_custom}>{children}</div> : children}
      <Footer />
    </Fragment>
  );
}

export default LayoutCustom;
