import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import Link from 'next/link';
import { breakPointLg } from '@/utils/constants';

import Badge from '../Badge';

import styles from './Navbar.module.scss';

import { Section as SectionType } from '../../types/navbar';
import { trackClick } from '@/utils/dataLayer/home';

type NavbarT = {
  sections: SectionType[];
  customClass?: string;
  callToAction?: JSX.Element;
  active?: string;
};
function Navbar({ sections, customClass, callToAction, active }: NavbarT) {
  const router = useRouter();
  const ref = useRef<HTMLUListElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const handleLinkClick = (section: SectionType) => {
    trackClick({ action: `Click ${section.label} - Menú` });

    router.push(section.link ?? '');
  };

  const liClasses = (badge: boolean, active = false) =>
    classnames({
      [styles.align_badge]: badge,
      [styles.section_active]: active,
    });

  const navbarClasses = classnames(styles.navbar, {
    [`${customClass}`]: !!customClass,
  });

  const ulClasses = classnames(styles.navbar_sections);

  const [showSections, setShowSections] = useState(false);
  const [scroll, setScroll] = useState(0);

  const showSectionsClick = () => setShowSections(!showSections);

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.pageYOffset);

      const sectionsRef = ref.current;

      if (window.innerWidth <= breakPointLg) {
        setShowSections(false);
        sectionsRef?.classList.remove(styles.navbar_section_scrolled);
      }

      if (window.pageYOffset > scroll) {
        labelRef.current?.classList.add(styles.hide);
      } else {
        labelRef.current?.classList.remove(styles.hide);
      }
    };

    const onResize = () => {
      if (window.innerWidth > breakPointLg) {
        setShowSections(true);
      } else {
        setShowSections(false);
      }
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, [scroll]);

  return (
    <div className={navbarClasses}>
      <ul ref={ref} className={ulClasses}>
        {sections.map((section: SectionType, key: number) => (
          <li
            key={`navbar_section_${key}`}
            className={liClasses(section.badge, active === section.id)}
          >
            {section.badge && (
              <div>
                <Badge
                  text="NUEVO"
                  color="54CA5C"
                  variant="colorized"
                  iconPosition="none"
                  size="small"
                  customClass={styles.badge_navbar}
                />
              </div>
            )}
            <Link onClick={() => handleLinkClick(section)} href={section.link ?? '/'}>
              {section.label}
            </Link>
          </li>
        ))}

        {!!callToAction && showSections && (
          <li className={styles.navbar_call_to_action}>{callToAction}</li>
        )}
      </ul>
      <div className={styles.navbar_overlay} onClick={showSectionsClick} aria-hidden="true" />
    </div>
  );
}

export default Navbar;
