import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { headerLogoColor, headerLogoWhite } from '@/utils/constants';
import { LOGO } from '@/utils/assets';
import routes from '@/utils/routes';

import styles from './HeaderCustom.module.scss';

type HeaderT = {
  sticky?: boolean;
  type?: typeof headerLogoColor | typeof headerLogoWhite;
  children?: any;
  hide?: boolean;
  wizard?: boolean;
  customClass?: string;
  logoRedirect?: boolean;
  brandingLogo?: string;
};

const HeaderCustom = ({
  sticky = false,
  type = headerLogoColor,
  children,
  hide = false,
  wizard = false,
  customClass,
  logoRedirect = false,
  brandingLogo,
}: HeaderT) => {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  const imgLogo = type === headerLogoWhite ? LOGO.WHITE : LOGO.COLOR;

  const classLogo = classnames(styles.header_logo, {
    [styles.header_wizard]: !!wizard,
    [styles.header_logo_redirect]: logoRedirect,
  });

  const headerClass = useMemo(() => {
    const headerRef = ref.current;
    const previousPosition = window.scrollY;

    return classnames(
      styles.header,
      {
        [styles.header_sticky]: !!sticky,
        [styles.header_hide]: !!sticky && !!hide,
      },
      styles.scrolled,
      customClass
    );
  }, [scrollY]);

  const onClickLogoHandler = () => {
    if (logoRedirect) {
      router.push(routes.landing);
    }
  };

  return (
    <header ref={ref} className={headerClass}>
      <div className={classLogo}>
        <img
          className={styles.header_logo_img}
          src={imgLogo}
          alt="bicevida landing header"
          width={160}
          height={20}
          onClick={onClickLogoHandler}
        />
        {!!brandingLogo && (
          <>
            <img className={styles.logo_sponsor} src={brandingLogo} alt="Logo de sponsor" />
          </>
        )}
      </div>
      {children}
    </header>
  );
};

export default HeaderCustom;
