import React, { Fragment } from 'react';
import classnames from 'classnames';

import Image from 'next/image';
import Text from '../../designSystem/Text';
import { Row, Col } from '../../designSystem/Container';
import Badge from '../Badge';

import styles from './Footer.module.scss';

import { bicevidaURL } from '../../utils/constants';
import { footerSectionTexts } from '../../utils/generalTexts';
import { LOGO } from '../../utils/assets';

const Footer = () => {
  const logoClick = () => window.open(bicevidaURL, '_blank');

  const linkClasses = (badge: boolean) =>
    classnames(styles.link, {
      [styles.align_badge]: badge,
    });

  return (
    <footer className={styles.footer}>
      <Col>
        <Row className={styles.actions_container}>
          <Col>
            <Image
              src={LOGO.COLOR}
              alt="bicevida landing footer"
              className={styles.logo}
              onClick={logoClick}
              width={119}
              height={15}
            />
          </Col>

          <Col>
            <div className={styles.links_container}>
              {footerSectionTexts.links.map((link: any, index: number) => (
                <Fragment key={`footer_corporate_link_${index}`}>
                  {link.badge && (
                    <Badge
                      text="NUEVO"
                      color="54CA5C"
                      variant="colorized"
                      iconPosition="none"
                      size="small"
                      customClass={styles.badge_footer}
                    />
                  )}
                  <span
                    className={linkClasses(link.badge)}
                    onClick={() => window.open(link.url, '_blank')}
                  >
                    {link.text}
                  </span>
                </Fragment>
              ))}
            </div>
          </Col>
          <Col>
            <div className={styles.networks_container}>
              {footerSectionTexts.socialNetworks.map((socialNetwork, index: number) => (
                <Image
                  key={`footer_social_network_link_${index}`}
                  className={styles.networks_img}
                  src={socialNetwork.icon}
                  alt={socialNetwork.alt}
                  onClick={() => window.open(socialNetwork.url, '_blank')}
                  width={19}
                  height={19}
                />
              ))}
            </div>
          </Col>
        </Row>
        <Col>
          <Row className={styles.separator_container}>
            <hr className={styles.horizontal_separator} />
          </Row>
        </Col>
        <Row className={styles.copyright_content}>
          <div className={styles.phone_copyright}>
            <Text text={footerSectionTexts.clientService} customClass={styles.phone_copyright} />
            <span
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
              className={styles.phone_copyright}
              onClick={() => window.open(`tel:${footerSectionTexts.phone}`, '_blank')}
            >
              {footerSectionTexts.phone}
            </span>
          </div>
          <Text text={footerSectionTexts.copyright} customClass={styles.rights_copyright} />
        </Row>
      </Col>
    </footer>
  );
};

export default Footer;
