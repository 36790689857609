import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import Image from 'next/image';

import styles from './PromoHeader.module.scss';
import PromoModal from './PromoModal';
import { IntervalCyber } from '@/utils/format';

type InfoType = {
  cyberDay: IntervalCyber | null;
};

function PromoHeader({ cyberDay }: InfoType) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const subtext = useMemo(() => {
    switch (cyberDay) {
      case IntervalCyber.CyberDay:
        return '3, 4 y 5 de junio';
      case IntervalCyber.Extension:
        return '6 y 7 de junio';
      default:
        return '';
    }
  }, [cyberDay]);

  return (
    <div className={styles.cyber_header}>
      <PromoModal isOpen={isOpen} setIsOpen={setIsOpen} cyberDay={cyberDay} />

      <div className={styles.cyber_header_container}>
        <div className={styles.cat_container}>
          {cyberDay === IntervalCyber.Extension ? (
            <Image src="/cat-cyber-nav-2.png" alt="" width={201} height={119} />
          ) : cyberDay === IntervalCyber.CyberDayLastDay ? (
            <Image src="/cat-cyber-nav-3.png" alt="" width={166} height={120} />
          ) : (
            <Image src="/cat-cyber-nav-1.png" alt="" width={192} height={120} />
          )}
        </div>
        <div className={styles.left_section}>
          {cyberDay === IntervalCyber.Extension ? (
            <div className={styles.extended}>¡Extendimos!</div>
          ) : cyberDay === IntervalCyber.CyberDayLastDay ? (
            <div className={styles.last_day_extended}>¡Último día!</div>
          ) : null}
          <Image src="/cyber-safe.svg" alt="Cyber safe" width={123} height={54} quality={100} />
          <div className={styles.subtext}>{subtext}</div>
        </div>
        <div className={styles.middle_section}>
          <div className={styles.text_desc}>
            Contrata hoy <span>y llévate hasta</span>
          </div>
          <div className={styles.price}>
            <div className={styles.price_percent}>
              50
              <div className={styles.subprice}>
                <div className={styles.percent}>%</div>
                <div className={styles.discount}>dcto</div>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.price_preposition}>En una facturación.</div>
              <div onClick={handleClick} className={styles.details_btn} tabIndex={0} role="button">
                Ver detalles
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoHeader;
