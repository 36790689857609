import React from 'react';

import { Button, Text } from '@BICE_Vida/components';

import { Modal, ModalBody, ModalContent } from '@BICE_Vida/scp-components';

import { useRouter } from 'next/router';
import { CAT } from '@/utils/assets';

import styles from './PromoHeader.module.scss';
import { cyberDayModal } from '@/utils/generalTexts';
import { IntervalCyber } from '@/utils/format';
import routes from '@/utils/routes';

type InfoType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  cyberDay: IntervalCyber | null;
  fromWizard?: boolean;
};

function PromoModal({ isOpen, setIsOpen, cyberDay, fromWizard }: InfoType) {
  const router = useRouter();

  const onClickFunction = () => {
    setIsOpen(!isOpen);
  };

  const btnEstimateClick = () => {
    router.push(routes.wizard);
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClickFunction} closeOnOverlay classNameModal={styles.modal}>
        <div>
          <ModalBody
            img={CAT.CAT_CYBERDAY}
            className={styles.modal_body}
            content={
              <ModalContent title={cyberDayModal.title} className={styles.text_modal_content}>
                <Text className={styles.text_modal_body}>{cyberDayModal.body}</Text>
                <Text className={styles.text_modal_footer}>
                  {cyberDayModal.subFooter(
                    cyberDay === IntervalCyber.Extension ||
                      cyberDay === IntervalCyber.CyberDayLastDay
                  )}
                </Text>
              </ModalContent>
            }
          />
        </div>
        <div className={styles.container_buttons}>
          {!fromWizard && (
            <Button
              text="Entendido"
              variant="text"
              size="large"
              onClick={onClickFunction}
              className={styles.button_text}
            />
          )}
          <Button
            text={fromWizard ? 'Entendido' : 'Cotizar ahora'}
            variant="primary"
            size="large"
            loading={false}
            onClick={fromWizard ? onClickFunction : btnEstimateClick}
          />
        </div>
      </Modal>
    </div>
  );
}

export default PromoModal;
